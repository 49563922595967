import logo from "./images/logo.svg";
import "./App.css";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='app-logo' alt='logo' />
        <h1 className='main-title color-main'>Coming soon</h1>

        <a href='mailto:office@businessjet.hu' className='color-second'>
          office@businessjet.hu
        </a>
      </header>
    </div>
  );
}

export default App;
